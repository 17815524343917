import * as React from "react"
import type { PageProps } from "gatsby"
import Layout from "../components/layout"

const IndexRoute = ({ path }: PageProps) => {
  return (
    <Layout>
      <div className="">
        <div className=" h-screen grid grid-cols-2 text-5xl pt-80">
          <div className="justify-self-end pr-4">I am a</div>
          <ul className="">
            <li className="font-bold">Problem Solver</li>
            <li className="">MedTech Enthusiast</li>
            <li className="">Engineer</li>
            <li className="">Entrepreneur</li>
          </ul>
        </div>
        <div className="h-screen bg-stone-900 text-white grid grid-cols-2">
          <h2 className="justify-self-center self-center">About me.</h2>
          <div className="justify-self-start self-center pr-32">
            <p className="py-12">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              hic, quia, laudantium incidunt aperiam voluptatem libero animi
              nemo nam quisquam suscipit cupiditate, expedita nihil eligendi
              enim quam amet et! Mollitia possimus sequi pariatur veniam at
              similique, repellat in accusantium quasi totam magni magnam sed
              tempore! Nesciunt iusto aliquam ducimus optio iste adipisci veniam
              magni! Quidem aliquam ea tempora illo, pariatur quo ullam rerum
              reprehenderit ex! Culpa error quidem sequi cum nobis facilis
              ratione repudiandae aspernatur sit vero natus ut, vel, excepturi
              molestias asperiores quas esse repellendus tenetur incidunt
              voluptatum perspiciatis eaque eos. Necessitatibus quos dicta
              ipsum. Magni quibusdam ut unde?
            </p>
            <p className="py-12">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro
              quidem ipsam iure fugit provident ad ducimus inventore placeat
              aspernatur nam nostrum numquam, ut accusamus adipisci dolorum!
              Atque, earum? Ullam, nam.
            </p>
          </div>
        </div>
        <div className=" h-screen bg-stone-300 grid grid-cols-2">
          <div className="justify-self-end self-center pl-32">
            <p className="py-12">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              hic, quia, laudantium incidunt aperiam voluptatem libero animi
              nemo nam quisquam suscipit cupiditate, expedita nihil eligendi
              enim quam amet et! Mollitia possimus sequi pariatur veniam at
              similique, repellat in accusantium quasi totam magni magnam sed
              tempore! Nesciunt iusto aliquam ducimus optio iste adipisci veniam
              magni! Quidem aliquam ea tempora illo, pariatur quo ullam rerum
              reprehenderit ex! Culpa error quidem sequi cum nobis facilis
              ratione repudiandae aspernatur sit vero natus ut, vel, excepturi
              molestias asperiores quas esse repellendus tenetur incidunt
              voluptatum perspiciatis eaque eos. Necessitatibus quos dicta
              ipsum. Magni quibusdam ut unde?
            </p>
            <p className="py-12">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro
              quidem ipsam iure fugit provident ad ducimus inventore placeat
              aspernatur nam nostrum numquam, ut accusamus adipisci dolorum!
              Atque, earum? Ullam, nam.
            </p>
          </div>
          <h2 className="justify-self-center self-center">Projects.</h2>
        </div>
        <div className=" h-screen bg-stone-600 text-white grid grid-cols-2">
          <h2 className="justify-self-center self-center">Work.</h2>
          <div className="justify-self-start self-center pr-32">
            <p className="py-12">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              hic, quia, laudantium incidunt aperiam voluptatem libero animi
              nemo nam quisquam suscipit cupiditate, expedita nihil eligendi
              enim quam amet et! Mollitia possimus sequi pariatur veniam at
              similique, repellat in accusantium quasi totam magni magnam sed
              tempore! Nesciunt iusto aliquam ducimus optio iste adipisci veniam
              magni! Quidem aliquam ea tempora illo, pariatur quo ullam rerum
              reprehenderit ex! Culpa error quidem sequi cum nobis facilis
              ratione repudiandae aspernatur sit vero natus ut, vel, excepturi
              molestias asperiores quas esse repellendus tenetur incidunt
              voluptatum perspiciatis eaque eos. Necessitatibus quos dicta
              ipsum. Magni quibusdam ut unde?
            </p>
            <p className="py-12">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro
              quidem ipsam iure fugit provident ad ducimus inventore placeat
              aspernatur nam nostrum numquam, ut accusamus adipisci dolorum!
              Atque, earum? Ullam, nam.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexRoute
